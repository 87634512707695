var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AppInfoForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入名称",
                            trigger: "change",
                          },
                        ],
                        prop: "name",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { width: _vm.inputWidth, placeholder: "请输入" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.pageType === 1
                    ? _c("el-form-item", { attrs: { label: "类型" } }, [
                        _c("div", {
                          staticClass: "text",
                          domProps: {
                            textContent: _vm._s(
                              _vm.appTypeMap[_vm.form.appType]
                            ),
                          },
                        }),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "类型",
                            rules: [
                              {
                                required: true,
                                message: "请选择类型",
                                trigger: "change",
                              },
                            ],
                            prop: "appType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              width: _vm.inputWidth,
                              options: _vm.appTypeOps,
                            },
                            model: {
                              value: _vm.form.appType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "appType", $$v)
                              },
                              expression: "form.appType",
                            },
                          }),
                        ],
                        1
                      ),
                  _vm.pageType === 1
                    ? _c("el-form-item", { attrs: { label: "appId" } }, [
                        _c("div", {
                          staticClass: "text",
                          domProps: { textContent: _vm._s(_vm.form.appId) },
                        }),
                        _c("label", { staticClass: "tips" }, [
                          _vm._v(
                            "注：该字段为唯一标识，操作之前请务必联系系统管理员"
                          ),
                        ]),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "appId",
                            rules: [
                              {
                                required: true,
                                message: "请输入appId",
                                trigger: "change",
                              },
                            ],
                            prop: "appId",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              width: _vm.inputWidth,
                              maxlength: 100,
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.form.appId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "appId", $$v)
                              },
                              expression: "form.appId",
                            },
                          }),
                          _c("label", { staticClass: "tips" }, [
                            _vm._v(
                              "注：该字段为唯一标识，操作之前请务必联系系统管理员"
                            ),
                          ]),
                        ],
                        1
                      ),
                  _vm.form.appType !== 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "appSecret",
                            rules: [
                              {
                                required: true,
                                message: "请输入appSecret",
                                trigger: "change",
                              },
                            ],
                            prop: "privateKey",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              width: _vm.inputWidth,
                              maxlength: 100,
                              placeholder: "请输入",
                            },
                            model: {
                              value: _vm.form.privateKey,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "privateKey", $$v)
                              },
                              expression: "form.privateKey",
                            },
                          }),
                          _c("label", { staticClass: "tips" }, [
                            _vm._v(
                              "注：该字段为秘钥，操作之前请务必联系系统管理员"
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.appType === 1
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "aes加密",
                              rules: [
                                {
                                  required: true,
                                  message: "请输入aes加密",
                                  trigger: "change",
                                },
                              ],
                              prop: "name",
                            },
                          },
                          [
                            _c("v-input", {
                              attrs: {
                                width: _vm.inputWidth,
                                maxlength: 100,
                                placeholder: "请输入aes加密",
                              },
                              model: {
                                value: _vm.form.aesKey,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "aesKey", $$v)
                                },
                                expression: "form.aesKey",
                              },
                            }),
                            _c("label", { staticClass: "tips" }, [
                              _vm._v(
                                "注：该字段从支付宝控制台：开发设置-开发信息-接口内容加密方式 中获取"
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "加密方式",
                              rules: [
                                {
                                  required: true,
                                  message: "请输入加密方式",
                                  trigger: "change",
                                },
                              ],
                              prop: "signMethod",
                            },
                          },
                          [
                            _c("v-input", {
                              attrs: {
                                width: _vm.inputWidth,
                                maxlength: 100,
                                placeholder: "请输入加密方式",
                              },
                              model: {
                                value: _vm.form.signMethod,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "signMethod", $$v)
                                },
                                expression: "form.signMethod",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "公钥",
                              rules: [
                                {
                                  required: true,
                                  message: "请输入公钥",
                                  trigger: "change",
                                },
                              ],
                              prop: "publicKey",
                            },
                          },
                          [
                            _c("v-textarea", {
                              key: "publicKey",
                              attrs: {
                                maxlength: 10000000,
                                placeholder: "请输入公钥",
                              },
                              model: {
                                value: _vm.form.publicKey,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "publicKey", $$v)
                                },
                                expression: "form.publicKey",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "私钥",
                              rules: [
                                {
                                  required: true,
                                  message: "请输入私钥",
                                  trigger: "change",
                                },
                              ],
                              prop: "privateKey",
                            },
                          },
                          [
                            _c("v-textarea", {
                              key: "privateKey",
                              attrs: {
                                maxlength: 1000000,
                                placeholder: "请输入私钥",
                              },
                              model: {
                                value: _vm.form.privateKey,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "privateKey", $$v)
                                },
                                expression: "form.privateKey",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.form.appType === 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "关联公众号", prop: "officialId" } },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                model: {
                                  value: _vm.form.officialId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "officialId", $$v)
                                  },
                                  expression: "form.officialId",
                                },
                              },
                              "v-select2",
                              _vm.pageParams,
                              false
                            )
                          ),
                          _c("label", { staticClass: "tips" }, [
                            _vm._v("注：页面无反应或报错，请参考以下内容："),
                            _c("br"),
                            _vm._v(
                              "\n                        1. 跳转的h5页面的域名要在小程序加白名单 "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                        2. h5页面内调用jssdk，需要公众号内设置JS接口安全域名 "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                        3. 设置与开发 => 公众号设置 => 功能设置 => JS接口安全域名 "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n                        4. 调接口的ip要加到微信公众号ip白名单 自己调用就是自己的ip 服务器调用就是服务器的ip "
                            ),
                            _c("br"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "应用类型", prop: "appStatus" } },
                    [
                      _c("v-select", {
                        attrs: {
                          width: _vm.inputWidth,
                          options: _vm.appStatusOps,
                        },
                        model: {
                          value: _vm.form.appStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "appStatus", $$v)
                          },
                          expression: "form.appStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }