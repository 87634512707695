<template>
    <div class="AppInfoForm-wrapper">
        <form-panel
            ref="formPanel"
            :form="form"
            v-bind="submitConfig"
            :submitBefore="submitBefore"
            @update="update"
        >
            <col2-detail>
                <col2-block title="基础信息">
                    <el-form-item
                        label="名称"
                        :rules="[
                            {
                                required: true,
                                message: '请输入名称',
                                trigger: 'change',
                            },
                        ]"
                        prop="name"
                    >
                        <v-input v-model="form.name" :width="inputWidth" placeholder="请输入" />
                    </el-form-item>
                    <el-form-item v-if="pageType === 1" label="类型">
                        <div class="text" v-text="appTypeMap[form.appType]" />
                    </el-form-item>
                    <el-form-item
                        v-else
                        label="类型"
                        :rules="[
                            {
                                required: true,
                                message: '请选择类型',
                                trigger: 'change',
                            },
                        ]"
                        prop="appType"
                    >
                        <v-select
                            v-model="form.appType"
                            :width="inputWidth"
                            :options="appTypeOps"
                        />
                    </el-form-item>
                    <el-form-item v-if="pageType === 1" label="appId">
                        <div class="text" v-text="form.appId" />
                        <label class="tips"
                            >注：该字段为唯一标识，操作之前请务必联系系统管理员</label
                        >
                    </el-form-item>
                    <el-form-item
                        v-else
                        label="appId"
                        :rules="[
                            {
                                required: true,
                                message: '请输入appId',
                                trigger: 'change',
                            },
                        ]"
                        prop="appId"
                    >
                        <v-input v-model="form.appId" :width="inputWidth" :maxlength="100" placeholder="请输入" />
                        <label class="tips"
                            >注：该字段为唯一标识，操作之前请务必联系系统管理员</label
                        >
                    </el-form-item>
                    <el-form-item
                        v-if="form.appType !== 1"
                        label="appSecret"
                        :rules="[
                            {
                                required: true,
                                message: '请输入appSecret',
                                trigger: 'change',
                            },
                        ]"
                        prop="privateKey"
                    >
                        <v-input v-model="form.privateKey" :width="inputWidth" :maxlength="100" placeholder="请输入" />
                        <label class="tips"
                            >注：该字段为秘钥，操作之前请务必联系系统管理员</label
                        >
                    </el-form-item>
                    
                    <template v-if="form.appType === 1">
                        <el-form-item
                            label="aes加密"
                            :rules="[
                                {
                                    required: true,
                                    message: '请输入aes加密',
                                    trigger: 'change',
                                },
                            ]"
                            prop="name"
                        >
                            <v-input v-model="form.aesKey" :width="inputWidth" :maxlength="100" placeholder="请输入aes加密" />
                            <label class="tips">注：该字段从支付宝控制台：开发设置-开发信息-接口内容加密方式 中获取</label>
                        </el-form-item>
                        <el-form-item
                            label="加密方式"
                            :rules="[
                                {
                                    required: true,
                                    message: '请输入加密方式',
                                    trigger: 'change',
                                },
                            ]"
                            prop="signMethod"
                        >
                            <v-input v-model="form.signMethod" :width="inputWidth" :maxlength="100" placeholder="请输入加密方式" />
                        </el-form-item>
                        <el-form-item
                            label="公钥"
                            :rules="[
                                {
                                    required: true,
                                    message: '请输入公钥',
                                    trigger: 'change',
                                },
                            ]"
                            prop="publicKey"
                        >
                            <v-textarea v-model="form.publicKey" :maxlength="10000000" key='publicKey' placeholder="请输入公钥"></v-textarea>
                        </el-form-item>
                        <el-form-item
                            label="私钥"
                            :rules="[
                                {
                                    required: true,
                                    message: '请输入私钥',
                                    trigger: 'change',
                                },
                            ]"
                            prop="privateKey"
                        >
                            <v-textarea v-model="form.privateKey" :maxlength="1000000" key="privateKey" placeholder="请输入私钥"></v-textarea>
                        </el-form-item>
                    </template>
                    
                    <!-- 接口内容加密方式 -->
                    <el-form-item
                        v-if="form.appType === 2"
                        label="关联公众号"
                        prop="officialId"
                    >
                         <v-select2 v-model="form.officialId" v-bind="pageParams" ></v-select2>
                        <label class="tips">注：页面无反应或报错，请参考以下内容：<br/>
                            1. 跳转的h5页面的域名要在小程序加白名单 <br/>
                            2. h5页面内调用jssdk，需要公众号内设置JS接口安全域名 <br/>
                            3. 设置与开发 => 公众号设置 => 功能设置 => JS接口安全域名 <br/>
                            4. 调接口的ip要加到微信公众号ip白名单 自己调用就是自己的ip 服务器调用就是服务器的ip <br/>
                        </label>
                    </el-form-item>
                    <el-form-item
                        label="应用类型"
                        prop="appStatus"
                    >
                        <v-select
                            v-model="form.appStatus"
                            :width="inputWidth"
                            :options="appStatusOps"
                        />
                    </el-form-item>
                </col2-block>
            </col2-detail>
        </form-panel>
    </div>
</template>

<script>
import { uploadURL } from 'common/api'
import { updateURL, queryDetailURL, getOfficialAccountListURL} from './api'
import { Col2Block, Col2Detail, CheckboxPlus } from '@/components/bussiness'
import { vUploader } from 'components/control'
import { setAppTypeOps, appTypeMap, setAppStatusOps } from './map'
import _ from 'lodash'

export default {
    name: 'AppInfoForm',
    components: {
        Col2Block,
        Col2Detail,
        CheckboxPlus,
        vUploader
    },
    data() {
        return {
            width: 500,
            pageType: 0,
            submitConfig: {
                queryUrl: queryDetailURL,
                submitUrl: updateURL
            },
            uploadURL: `${uploadURL}?module=icon`,
            appTypeMap,
            qrCodeUrl: '',
            appTypeOps: setAppTypeOps(),
            appStatusOps: setAppStatusOps(),
            pageParams: {
                searchUrl: getOfficialAccountListURL,
                request: {
                    text: 'keyword',
                    value: 'id'
                },
                response: {
                    text: 'appName',
                    value: 'id'
                }
            },
            form: {
                id: undefined,
                appId: '',
                name: '',
                aesKey: '', // aes密钥（支付宝高级授权需要，微信暂时不用次字段）
                appType: undefined,
                publicKey: '', // 公钥
                privateKey: '', // 当为appType为1支付宝时，privateKey 为私钥；不是支付宝小程序应传 appSecret内容
                signMethod: '', // 加密方式
                officialId: undefined, // 关联公众号id
                appStatus: 1, // 0 关闭  1 正常
            },
            inputWidth: 200
        }
    },
    created() {
    },
    mounted() {
        const { id } = this.$route.query
        if (id !== undefined) {
            this.$setBreadcrumb('编辑')
            this.pageType = 1
            this.$refs.formPanel.getData({
                id
            })
        } else {
            this.isCreate = true
            this.$setBreadcrumb('新增')
            this.form.appType = this.appTypeOps[0].value
        }
    },
    methods: {
        update(data) {
            this.form = {
                id: data.id,
                appId: data.microAppId,
                name: data.name,
                aesKey: data.aesKey, // aes密钥（支付宝高级授权需要，微信暂时不用次字段）
                appType: data.appType,
                publicKey: data.publicKey, // 公钥
                privateKey: data.microPrivateKey, // 当为appType为1支付宝时，privateKey 为私钥；不是支付宝小程序应传 appSecret内容
                signMethod: data.signMethod, // 加密方式
                officialId: data.officialAccountInfoV.id, // 关联公众号ID
                appStatus: data.appStatus, // 应用状态
            }
        },
        async submitBefore(data) {

            return true
        }
    }
}
</script>

<style lang="scss" scoped>
.AppInfoForm-wrapper {
    .text {
        display: inline-block;
        min-width: 144px;
    }
    .tips {
        margin-left: 20px;
    }
    .title {
        width: 742px;
        background-color: #e3e8ef;
        padding: 5px 0 5px 5px;
        margin-bottom: 20px;
    }
}
</style>
